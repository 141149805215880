import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import ContactSourceService from '@/services/contact-source.service'
import { ContactsFileUploadResponse } from '@/classes/ContactsFileUploadResponse'
import { CsvFileConfig } from '@/models/CsvFileConfig'
import { Import } from '@/classes/Import.js'
import vMultiselectListbox from 'vue-multiselect-listbox'
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css'
import SyncService from '@/services/sync.service'
import EventBus from '@/util/EventBus'
import MultiSelect from '@/components/MultiSelect/MultiSelect.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import ContactsTable from './ContactsTable/ContactsTable.vue'
import getEnv from '@/util/env'
import Preview from './Preview/Preview.vue'
import HelpText from '@/components/Layout/HelpText/HelpText.vue'
import CountryService from '@/services/country.service'

export default {
  name: 'FilterDb',
  components: {
    HeaderTopDashboard,
    ButtonMultimedia,
    ContactsTable,
    vMultiselectListbox,
    Preview,
    MultiSelect,
    HelpText,
  },
  data () {
    return {
      importObject: new Import(),
      contactsFileUploadResponse: null,
      preview: [],
      skipHeader: null,
      delimiter: null,
      stepperStep: 1,
      dataCountry: [],
      dataCountryKeys: [],
      csvFileConfig: new CsvFileConfig(),
      confirmContactConsent: false,
      userGroups: [],
      collisionActionSelected: null,
      importGroups: [],
      newGroups: [],
      newGroupName: '',
      frecuencies: [],
      loadingFirstStepAction: false,
      customFields: [],
      selectedField: null,
      selectedType: '',
      availableFieldsAdvanced: [],
      typeInfoItems: [],
      selectedColumn: null,
      newFieldType: 'string',
      newFieldName: '',
      newFieldColumn: null,
      countries: [],
      impactedItems: [
        { text: this.$t('Último mes').toString(), value: 1 },
        { text: this.$t('Últimos 2 meses').toString(), value: 2 },
        { text: this.$t('Últimos 3 meses').toString(), value: 3 },
      ],
      limitContacts: 1000,
      monthsBefore: 1,
      events: [],
      trackingEvents: [
        { text: 'No recibidos', id: 'undelivered' },
        { text: 'Rechazados', id: 'rejected' },
        { text: 'Caducados', id: 'expired' },
      ],
      panel: true,
      switchUndelivered: false,
      switchExpired: false,
      switchRejected: false,
      switchBlacklisted: true,
    }
  },
  computed: {
    fileColumns: function () {
      if (!this.contactsFileUploadResponse && this.preview.length === 0) {
        return []
      }
      const numColumns = this.contactsFileUploadResponse ? this.contactsFileUploadResponse.filePreview[0].length : this.preview.length
      const fileColumns = []
      fileColumns.push({ value: null, text: '' })
      for (let i = 1; i < numColumns + 1; i++) {
        fileColumns.push({ value: i, text: this.$t('Columna').toString() + ' ' + i })
      }
      return fileColumns
    },
    canContinue: function () {
      return this.contactsFileUploadResponse &&
      (this.importObject.columnsMap.phone !== '' && this.importObject.columnsMap.phone !== null) &&
      (this.importObject.countryIso !== '' && this.importObject.countryIso !== null)
    },
  },
  methods: {
    clearInputFile () {
      this.$refs.uploaderElement.value = null
    },
    mockUploadFile () {
      const file = this.$refs.uploaderElement
      file.click()
    },

    changeFile () {
      this.file = this.$refs.uploaderElement.files[0]
      if (this.file) {
        EventBus.$emit('showLoading', true)
        const formData = new FormData()
        formData.append('file', this.file, this.file.name)
        formData.append('skipHeader', this.importObject.skipHeader)
        formData.append('separator', this.importObject.separator)
        this.clearInputFile()

        SyncService.uploadFileFilter(formData)
        .then(
          (response) => {
            if (response.success === true) {
              this.importObject.fileName = response.fileName
              this.importObject.originalFilename = response.originalFilename
              this.csvFileConfig.fileName = response.fileName
              this.csvFileConfig.originalFilename = response.originalFilename
              this.sendFile()
            } else {
              EventBus.$emit('showAlert', 'danger', this.$t('Hubo un error al importar el achivo'))
            }
            this.loadingFirstStepAction = false
          },
          () => {
            EventBus.$emit('showAlert', 'danger', this.$t('Hubo un error al importar el achivo'))
          },
        )
        .finally(() => {
          this.loadingFirstStepAction = false
          EventBus.$emit('showLoading', false)
        })
      }
    },

    firstStepAction () {
      this.stepperStep = 2
    },

    sendFile () {
      const url = this.importObject.fileName ? 'reparseFile' : 'uploadFile'
      this.assignSkipHeaderAndDelimiter()

      ContactSourceService.sendFile(this.csvFileConfig, url)
        .then(
          (response) => {
            this.contactsFileUploadResponse = new ContactsFileUploadResponse(response.contactsFileUploadResult)
            this.assignCsvFileConfigAndImportObjectProperties()
          },
          () => {
            EventBus.$emit('showAlert', 'danger', this.$t('Hubo un error al subir el archivo').toString())
          },
        )
    },
    assignSkipHeaderAndDelimiter () {
      this.csvFileConfig.skipHeader = this.importObject.skipHeader
      this.csvFileConfig.delimiter = this.importObject.separator
    },
    assignCsvFileConfigAndImportObjectProperties () {
      this.importObject.fileName = this.contactsFileUploadResponse.fileName
      this.importObject.originalFilename = this.contactsFileUploadResponse.originalFilename
      this.importObject.skipHeader = this.contactsFileUploadResponse.skipHeader
      this.importObject.separator = this.contactsFileUploadResponse.delimiter
      this.csvFileConfig.originalFilename = this.contactsFileUploadResponse.originalFilename
      this.csvFileConfig.fileName = this.contactsFileUploadResponse.fileName
      this.csvFileConfig.filePath = this.contactsFileUploadResponse.fileName
    },
    downloadProcessFiltering () {
      const params = {
        fileName: this.importObject.fileName,
        limitContacts: this.limitContacts,
        monthsBefore: this.monthsBefore,
        phoneColumn: this.importObject.columnsMap.phone,
        countryIso: this.importObject.countryIso,
        skipHeader: this.importObject.skipHeader,
        separator: this.importObject.separator,
        events: this.events.length === 0 ? [] : this.events,
        removeBlacklisted: this.switchBlacklisted,
      }

      const user = JSON.parse(localStorage.getItem('user'))
      const objectAsUrlParams = new URLSearchParams(params).toString()
      const url = getEnv('backend_endpoint') + 'api/import/filter-csv' + '?' + objectAsUrlParams + '&token=' + user.token
      window.location.href = url
    },
    cleanProcessFiltering () {
      this.reset()
      this.beforeLeaving()
    },
    reset () {
      this.stepperStep = 1
      this.limitContacts = 1000
      this.importObject.columnsMap.phone = null
      this.monthsBefore = 1
      this.contactsFileUploadResponse = null
      this.switchUndelivered = false
      this.switchExpired = false
      this.switchRejected = false
      this.switchBlacklisted = true
      this.events = []
    },
    setEvent (event) {
      const found = this.events.find(ev => ev === event)
      if (found) {
        this.events = this.events.filter(ev => ev !== event)
      } else {
        this.events.push(event)
      }
    },
    getCoutries () {
      CountryService.getCountries()
        .then(
          (countries) => {
            for (const key in countries) {
              const element = countries[key]
              this.countries.push({ value: key, text: element })
            }
          },
          () => { },
        )
        .finally(() => {
        })
    },
    beforeLeaving () {
      const params = {
        fileName: this.importObject.fileName,
      }
      SyncService.cleanUp(params)
        .then(response => {
          console.log(response)
        })
    },
  },
  created () {

  },
  mounted () {
    this.importObject.type = 'manual'
    this.getCoutries()
  },
  beforeRouteLeave (to, from, next) {
    this.beforeLeaving()
    next()
  },
}
